.empty-animation {
  animation-name: zoomInStable;
  animation-duration: 3s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  -webkit-animation-name: zoomInStable;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: zoomInStable;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;
  -ms-animation-name: zoomInStable;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
  -o-animation-name: zoomInStable;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: 1;
  -o-animation-timing-function: linear;
}

.empty-animation {
  animation-name: zoomInStable;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: zoomInStable;
  -webkit-animation-duration: 3s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: zoomInStable;
  -moz-animation-duration: 3s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: zoomInStable;
  -ms-animation-duration: 3s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: zoomInStable;
  -o-animation-duration: 3s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

.empty-animation {
  animation-name: slideInUp;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  -webkit-animation-name: slideInUp;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: slideInUp;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;
  -ms-animation-name: slideInUp;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

.empty-animation {
  animation-name: slideInRight;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  -webkit-animation-name: slideInRight;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: slideInRight;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;
  -ms-animation-name: slideInRight;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

.empty-animation {
  animation-name: slideInLeft;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  -webkit-animation-name: slideInLeft;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: slideInLeft;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;
  -ms-animation-name: slideInLeft;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

.empty-animation {
  animation-name: slideInUp;
  animation-duration: 2s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  -webkit-animation-name: slideInUp;
  -webkit-animation-duration: 2s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: slideInUp;
  -moz-animation-duration: 2s;
  -moz-animation-iteration-count: 1;
  -moz-animation-timing-function: linear;
  -ms-animation-name: slideInUp;
  -ms-animation-duration: 2s;
  -ms-animation-iteration-count: 1;
  -ms-animation-timing-function: linear;
}

@-webkit-keyframes rotateme {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate(180deg);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 1;
  }
}

@-moz-keyframes rotateme {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    -moz-transform: rotate(180deg);
    opacity: 0.7;
  }
  100% {
    -moz-transform: rotate(360deg);
    opacity: 1;
  }
}

@-o-keyframes rotateme {
  0% {
    -o-transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    -o-transform: rotate(180deg);
    opacity: 0.7;
  }
  100% {
    -o-transform: rotate(360deg);
    opacity: 1;
  }
}

@keyframes rotateme {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
    opacity: 1;
  }
}

.testimonial-section .icon-layer-one,
.testimonial-section .icon-layer-two,
.testimonial-section .icon-layer-three,
.testimonial-section .icon-layer-four,
.about-section .circle-layer,
.testimonial-section .circle-layer,
.services-section-three .pattern-layer-one,
.services-section-three .pattern-layer-two,
.service-block-two .inner-box:hover .icon-four,
.service-block-two .inner-box:hover .icon-three,
.banner-section-two .content-column .pattern-layer-three,
.banner-section-two .content-column .pattern-layer-four,
.services-section-four .pattern-layer-two,
.services-section-four .pattern-layer-one,
.seo-section .pattern-layer-one,
.seo-section .pattern-layer-two,
.page-title .pattern-layer-four,
.step-block .inner-box .icon-box:before,
.step-block:nth-child(3) .inner-box .icon-box:before,
.service-block-five .inner-box .icon-box .border-layer,
.services-section-four
  .owl-carousel
  .owl-stage-outer
  .owl-item.center
  .service-block-four
  .inner-box
  .icon-three,
.services-section-four
  .owl-carousel
  .owl-stage-outer
  .owl-item.center
  .service-block-four
  .inner-box
  .icon-four {
  animation-name: rotateme;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotateme;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotateme;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: rotateme;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: rotateme;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

/* Bob Up Down*/

@-webkit-keyframes float_up_down {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

@keyframes float_up_down {
  0% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
  50% {
    transform: translateY(30px);
    transform: translateY(30px);
    opacity: 1;
  }
  100% {
    transform: translateY(0px);
    transform: translateY(0px);
    opacity: 1;
  }
}

.news-section .pattern-layer,
.pricing-section .pattern-layer-two,
.testimonial-section .icon-layer-five,
.call-to-action-section .circle-layer,
.services-section-three .pattern-layer-three,
.service-block-two .inner-box:hover .icon-two,
.gallery-section .pattern-layer,
.seo-section .pattern-layer-three,
.news-section-two .pattern-layer-custom,
.services-section-four
  .owl-carousel
  .owl-stage-outer
  .owl-item.center
  .service-block-four
  .inner-box
  .icon-two {
  animation-name: float_up_down;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float_up_down;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float_up_down;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float_up_down;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float_left_right;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

/* Bob */

@-webkit-keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

@keyframes float_left_right {
  0% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(100px);
    transform: translateX(100px);
    opacity: 0.4;
  }
  100% {
    -webkit-transform: translateX(-100px);
    transform: translateX(-100px);
    opacity: 1;
  }
}

.banner-section-three .icon-layer-one,
.services-section-four
  .owl-carousel
  .owl-stage-outer
  .owl-item.center
  .service-block-four
  .inner-box
  .icon-one,
.service-block-two .inner-box:hover .icon-one,
.main-footer .pattern-layer-two {
  animation-name: float_left_right;
  animation-duration: 30s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float_left_right;
  -webkit-animation-duration: 30s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float_left_right;
  -moz-animation-duration: 30s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float_left_right;
  -ms-animation-duration: 30s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float_left_right;
  -o-animation-duration: 30s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes float_left_right_two {
  0% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }
  50% {
    -webkit-transform: translateX(0px);
    opacity: 0.8;
  }
  100% {
    -webkit-transform: translateX(-30px);
    opacity: 1;
  }
}

@keyframes float_left_right_two {
  0% {
    transform: translateX(-30px);
    opacity: 1;
  }
  50% {
    transform: translateX(0px);
    opacity: 0.8;
  }
  100% {
    transform: translateX(-30px);
    opacity: 1;
  }
}

.page-title .pattern-layer-two,
.banner-section-three .icon-layer-three,
.banner-section-two .content-column .pattern-layer-two,
.banner-section .dotted-layer {
  animation-name: float_left_right_two;
  animation-duration: 5s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: float_left_right_two;
  -webkit-animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: float_left_right_two;
  -moz-animation-duration: 5s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: float_left_right_two;
  -ms-animation-duration: 5s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: float_left_right_two;
  -o-animation-duration: 5s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

@keyframes zoom-fade {
  0% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
  50% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    -webkit-transform: scale(0.8);
    transform: scale(0.8);
  }
}

.page-title .pattern-layer-three,
.banner-section-three .icon-layer-two,
.banner-section-two .content-column .pattern-layer-five,
.main-footer .pattern-layer-three {
  animation-name: zoom-fade;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: zoom-fade;
  -webkit-animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: zoom-fade;
  -moz-animation-duration: 8s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: zoom-fade;
  -ms-animation-duration: 8s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: zoom-fade;
  -o-animation-duration: 8s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}

@-webkit-keyframes rotatemetwo {
  0% {
    -webkit-transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    -webkit-transform: rotate(180deg);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: rotate(360deg);
    opacity: 1;
  }
}

@-moz-keyframes rotatemetwo {
  0% {
    -moz-transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    -moz-transform: rotate(-180deg);
    opacity: 0.7;
  }
  100% {
    -moz-transform: rotate(-360deg);
    opacity: 1;
  }
}

@-o-keyframes rotatemetwo {
  0% {
    -o-transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    -o-transform: rotate(-180deg);
    opacity: 0.7;
  }
  100% {
    -o-transform: rotate(-360deg);
    opacity: 1;
  }
}

@keyframes rotatemetwo {
  0% {
    transform: rotate(0deg);
    opacity: 1;
  }
  50% {
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
    opacity: 1;
  }
}

.step-block:nth-child(2) .inner-box .icon-box:before,
.step-block:nth-child(4) .inner-box .icon-box:before,
.service-block-five .inner-box .icon-box .dott-layer {
  animation-name: rotatemetwo;
  animation-duration: 15s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  -webkit-animation-name: rotatemetwo;
  -webkit-animation-duration: 15s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: rotatemetwo;
  -moz-animation-duration: 15s;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: rotatemetwo;
  -ms-animation-duration: 15s;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  -o-animation-name: rotatemetwo;
  -o-animation-duration: 15s;
  -o-animation-iteration-count: infinite;
  -o-animation-timing-function: linear;
}
