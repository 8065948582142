.top_logo {
  width: 170px;
  padding-top: 11px;
}

.bg-gray {
  background-color: #f3f4ff;
}

.cbl {
  color: #337ab7 !important;
}

.cb {
  color: #000;
}

.mb-75 {
  margin-bottom: 75px;
}

.fs-16 {
  font-size: 16px;
}

.fs-26 {
  font-size: 26px;
}

.fs-36 {
  font-size: 36px;
}

.w-32 {
  width: 32px;
}

.w-50p {
  width: 50px;
}

.icon-download {
  width: 72px;
  padding-left: 14px;
}

.color_green {
  color: #74aa42;
}

.non_padding {
  padding: 40px 0px 5px;
}

.spa {
  padding: 0 50px;
}

.update-breadcrumb {
  position: relative;
  overflow: hidden;
  padding: 10px 0px 0px;
}

.search-keyword {
  position: relative;
  overflow: hidden;
}

.search-keyword-type2 {
  position: relative;
  overflow: hidden;
}

.search-result {
  position: relative;
  margin-top: 20px;
  padding: 0px 0px 60px;
}

.update-section {
  position: relative;
  overflow: hidden;
  padding: 60px 0px 80px;
}

.update-section .sec-title .theme-btn {
  margin-top: 95px;
}

.update-section .pattern-layer {
  position: absolute;
  right: 0px;
  top: 280px;
  width: 304px;
  height: 281px;
  background-repeat: no-repeat;
}

a p.black {
  color: #000;
  font-weight: 500;
  font-size: 16px;
}

.text-l-cb {
  text-align: left;
  color: #000;
  font-size: 16px;
  font-weight: 300;
}

.text-l-cb a {
  text-align: left;
  color: #000;
}

.box_search {
  display: flex;
}

.date_after_search {
  margin-right: 15px;
  margin-top: 6px;
}

.legSelect {
  position: relative;
  display: block;
  width: 100%;
  line-height: 26px;
  height: 45px;
  font-size: 15px;
  color: #6c757d;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #dddddd;
  padding: 8px 16px 15px 20px;
  background-image: linear-gradient(45deg, transparent 50%, #ddd 60%),
    linear-gradient(135deg, #ddd 40%, transparent 50%) !important;
  background-position: calc(100% - 30px) 18px, calc(100% - 20px) 18px, 100% 0;
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.legSelect.h-60 {
  height: 60px !important;
  position: relative;
  display: block;
  width: 100%;
  line-height: 26px;
  font-size: 15px;
  color: #6c757d;
  border-radius: 6px;
  background: #ffffff;
  border: 1px solid #dddddd;
  padding: 10px 85px 10px 20px;
  background-image: linear-gradient(45deg, transparent 50%, #ddd 60%),
    linear-gradient(135deg, #ddd 40%, transparent 50%) !important;
  background-position: calc(100% - 30px) 26px, calc(100% - 20px) 26px, 100% 0;
  background-size: 10px 10px, 10px 10px;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.btn-style-three.h-45,
.btn-style-two.h-45,
.btn-style-twelve.h-45 {
  padding: 7px 44px !important;
}

a.btn-style-show_all {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  padding: 10px 60px;
  font-weight: 400;
  overflow: hidden;
  border-radius: 6px;
  overflow: hidden;
  background: #ff8404;
  text-transform: capitalize;
  background: -webkit-linear-gradient(to right, #ffc142 0%, #ff8404 100%);
  background: -moz-linear-gradient(to right, #ffc142 0%, #ff8404 100%);
  background: linear-gradient(to right, #ffc142 0%, #ff8404 100%);
}

a.btn-style-show_all::before {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  -webkit-transform: scale(0.2, 1);
  transform: scale(0.2, 1);
  background: #ea3368;
  background: -webkit-linear-gradient(to left, #ff8404 0%, #ffc142 100%);
  background: -moz-linear-gradient(to left, #ff8404 0%, #ffc142 100%);
  background: linear-gradient(to left, #ff8404 0%, #ffc142 100%);
}

a.btn-style-show_all:hover .txt {
  position: relative;
  z-index: 1;
}

a.btn-style-show_all:hover::before {
  opacity: 1;
  color: #ffffff;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

a.btn-style-show_all:hover {
  color: #ffffff;
}

button.btn-style-show_all {
  position: relative !important;
  display: inline-block !important;
  font-size: 16px !important;
  line-height: 30px !important;
  color: #ffffff !important;
  padding: 10px 60px !important;
  font-weight: 400 !important;
  overflow: hidden !important;
  border-radius: 6px !important;
  overflow: hidden !important;
  background: #ff8404 !important;
  text-transform: capitalize !important;
  background: -webkit-linear-gradient(to right, #ffc142 0%, #ff8404 100%) !important;
  background: -moz-linear-gradient(to right, #ffc142 0%, #ff8404 100%) !important;
  background: linear-gradient(to right, #ffc142 0%, #ff8404 100%) !important;
}

button.btn-style-show_all::before {
  position: absolute !important;
  content: '' !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  z-index: 1 !important;
  opacity: 0 !important;
  -webkit-transition: all 0.4s !important;
  -moz-transition: all 0.4s !important;
  -o-transition: all 0.4s !important;
  transition: all 0.4s !important;
  -webkit-transform: scale(0.2, 1) !important;
  transform: scale(0.2, 1) !important;
  background: #ea3368 !important;
  background: -webkit-linear-gradient(to left, #ff8404 0%, #ffc142 100%) !important;
  background: -moz-linear-gradient(to left, #ff8404 0%, #ffc142 100%) !important;
  background: linear-gradient(to left, #ff8404 0%, #ffc142 100%) !important;
  color: #ffffff !important;
}

button.swal.btn-style-show_all::before {
  display: none;
}

button.btn-style-show_all:hover .txt {
  position: relative !important;
  z-index: 1 !important;
}

button.btn-style-show_all:hover::before {
  opacity: 1 !important;
  color: #ffffff !important;
  -webkit-transform: scale(1, 1) !important;
  transform: scale(1, 1) !important;
}

button.btn-style-show_all:hover {
  color: #ffffff !important;
}

.banner-section-custom {
  position: relative;
  overflow: hidden;
}

.banner-section-custom .slide {
  position: relative;
  overflow: hidden;
  padding-top: 15px;
  padding-bottom: 200px;
  background-size: cover;
  background-repeat: no-repeat;
}

.banner-section-custom .dotted-layer {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-position: center top;
  background-repeat: no-repeat;
  transition: all 900ms ease;
  -moz-transition: all 900ms ease;
  -webkit-transition: all 900ms ease;
  -ms-transition: all 900ms ease;
  -o-transition: all 900ms ease;
}

ul.breadcrumb {
  padding: 10px 16px;
  list-style: none;
  background-color: #eee;
  color: var(--bs-gray);
}

ul.cbreadcrumb li {
  display: inline;
  font-size: 17px;
  font-weight: 500;
  color: var(--bs-gray);
}

ul.cbreadcrumb li + li:before {
  padding: 8px;
  color: var(--bs-gray);
  content: '/\00a0';
}

ul.cbreadcrumb li a {
  color: var(--bs-gray);
  text-decoration: none;
  font-weight: 500;
}

ul.cbreadcrumb li a:hover {
  color: var(--bs-gray);
  text-decoration: none;
}

.social-boxed {
  position: relative;
  display: inline-block;
}

.social-boxed:hover .social-inner {
  width: 100%;
}

.social-boxed .social-inner {
  position: relative;
  color: #ffffff;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  padding: 10px 20px 12px;
  width: 30%;
  max-width: 100%;
  height: 35px;
  overflow: hidden;
  background: #6917b4;
  background: -webkit-linear-gradient(to right, #6917b4 0%, #d54be1 100%);
  background: -moz-linear-gradient(to right, #6917b4 0%, #d54be1 100%);
  background: linear-gradient(to right, #6917b4 0%, #d54be1 100%);
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.social-boxed .social-inner a {
  position: relative;
  color: #ffffff;
  margin: -3px 8px 5px;
  font-size: 16px;
  float: left;
  opacity: 0;
}

.social-boxed:hover .social-inner a {
  opacity: 1;
}

.social-boxed .social-inner .share-now {
  position: relative;
  margin-right: 8px;
  margin-bottom: 4px;
  float: left;
  transition: all 300ms ease;
  -moz-transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
}

.news-block-two.style-two .inner-box .lower-content .post-meta li a .icon {
  color: #0029a5;
}

.social-boxed .social-inner {
  background: #ff8404;
  text-transform: capitalize;
  background: -webkit-linear-gradient(to right, #ffc142 0%, #ff8404 100%);
  background: -moz-linear-gradient(to right, #ffc142 0%, #ff8404 100%);
  background: linear-gradient(to right, #ffc142 0%, #ff8404 100%);
}

.mission-section.style-two {
  padding-top: 20px;
  margin-top: 0px;
}

.title-left {
  color: #74aa42;
  font-weight: 500;
  float: left;
}

.title-secm {
  float: right;
  font-weight: 600;
}

.display_search_result {
  margin-left: 150px;
  margin-right: 50px;
}

.hr_custom_org {
  height: 2px !important;
  color: #fd7e14;
  opacity: 0.5;
  margin: 50px 0px;
}

.hr_custom_gray {
  height: 2px !important;
  color: #6c757d;
  opacity: 0.1;
  margin: 50px 0px;
}

.badge {
  padding: 6px 10px;
  font-size: 13px;
  font-weight: 400;
}

.badge-primary {
  color: #fff;
  background-color: #007bff;
}

.display_result h5 {
  color: #74aa42;
}

.display_result .fa-calendar {
  color: var(--bs-primary);
  font-weight: 300;
}

.newsp {
  font-size: 16px;
  font-weight: 400;
}

.video-thumbnail {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.video-thumbnail::before {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  content: '';
  width: 100px;
  height: 70px;
  background-image: url(../images/icons/play-video.png);
  opacity: 0.5;
}

.video-thumbnail:hover {
  color: #eee;
  opacity: 1;
}

.video-thumbnail:hover::before {
  color: #eee;
  opacity: 1;
}

/* recommended */

.recommended h5 {
  color: #000;
  padding-bottom: 17px;
}

.recommended a p {
  color: #71ab48;
  font-size: 16px;
}

.tags_search h5 {
  color: #000;
  padding-bottom: 17px;
}

.tags_search a {
  position: relative;
  display: inline-block;
  line-height: 24px;
  padding: 5px 25px 5px;
  margin: 0px 6px 10px 0px;
  color: #777777;
  text-align: center;
  font-size: 16px;
  background: none;
  font-weight: 400;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  text-transform: capitalize;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.tags_search a:hover {
  border-color: #71ab48;
  background-color: #71ab48;
  color: #ffffff;
}

.podcast {
  display: flex;
}

.podcast h5 {
  color: #000;
  padding-bottom: 17px;
}

.podcast a {
  position: relative;
  display: inline-flex;
  line-height: 24px;
  padding: 15px 20px;
  margin: 0px 6px 10px 0px;
  color: #777777;
  text-align: center;
  font-size: 18px;
  background: none;
  font-weight: 400;
  height: 80px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #d7d7d7;
  transition: all 300ms ease;
  -webkit-transition: all 300ms ease;
  -ms-transition: all 300ms ease;
  -o-transition: all 300ms ease;
  -moz-transition: all 300ms ease;
}

.podcast a:hover {
  border-color: #71ab48;
  background-color: #71ab48;
  color: #ffffff;
}

.podcast .imgs {
  width: 41px;
}

.podcast img {
  width: 41px;
}

.podcast .content {
  padding-left: 20px;
  text-align: left;
}

.fb-page {
  padding-bottom: 30px;
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}

.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.share_under_search_bar {
  margin-top: -6px;
}

.complaint p {
  color: #000;
}

.testimonial-section .circle-layer {
  position: absolute;
  width: 428px;
  height: 467px;
  background-repeat: no-repeat;
  right: -280px;
  top: -172px;
}

.modal-content {
  padding: 11px 25px;
}

.modal-header {
  color: #000;
  display: block;
  border-bottom: 0px solid #dee2e6;
  padding-bottom: 0px;
}

.modal-header p {
  font-weight: 400;
  color: #625f5f;
  margin-bottom: 0px;
}

.modal .modal-header button.close {
  position: absolute;
  right: 17px;
  top: 15px;
}

.modal button.close {
  background-color: #fff;
}

.modal button.close {
  z-index: 1000;
}

.modal.vertically-modal .modal-dialog {
  transform: translateY(-25%);
  top: 50%;
  margin: 0 auto;
}

.modal.vertically-modal.show .modal-dialog {
  transform: translateY(-50%);
}

.modal-content {
  max-height: 100vh;
  overflow-y: auto;
  padding: 40px;
}

.sec_forgotpass {
  color: #74aa42;
}

.sec_forgotpass a {
  color: #74aa42;
}

.textor {
  width: 30px;
  background-color: #fff;
  text-align: center;
  position: absolute;
  bottom: -11px;
  left: 47%;
  right: 50%;
}

.testimonial-block .inner-box .author-image-outer:hover {
  filter: url("data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg'&gt;&lt;filter id='grayscale'&gt;&lt;feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/&gt;&lt;/filter&gt;&lt;/svg&gt;#grayscale");
  filter: gray;
  -webkit-filter: grayscale(100%);
  color: #6c757d;
}
.h160 {
  height: 160px;
}

.ta-right {
  text-align: right;
}
.blog-detail img {
  max-width: 100%;
  margin-bottom: 30px;
}
.hl {
  text-align: left;
}
.hl h5 {
  position: relative;
  line-height: 1.6em;
  font-weight: 400 !important;
  margin-top: 10px;
  font-size: 18px;
}

.hl h5,
.hl p {
  color: #74aa42;
  word-wrap: break-word;
  white-space: normal;
  overflow: hidden;
  display: -webkit-box;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  height: 55px;
}

.post-meta li a {
  position: relative;
  color: #555555;
  font-size: 14px;
}

.post-meta li a .fa-calendar {
  padding-right: 10px;
}

.po_relative {
  position: relative;
}

.po_relative a {
  position: absolute;
  right: 0px;
  top: 0px;
}
.not-found {
  color: #000;
}
.not-found ol li {
  list-style-type: inherit;
}
.blog-detail ol li {
  list-style-type: inherit;
}

/*Btn Style One*/

.btn-style-one-other {
  position: relative;
  display: inline-block;
  font-size: 16px;
  line-height: 30px;
  color: #fff;
  padding: 10px 30px;
  font-weight: 400;
  overflow: hidden;
  border-radius: 50px;
  overflow: hidden;
  text-transform: capitalize;
  background-color: #74aa42 !important;
  font-family: 'Kanit', sans-serif;
}

.btn-style-one-other:before {
  position: absolute;
  content: '';
  left: 110%;
  top: 0px;
  width: 120%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  background-color: #74aa42 !important;
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
  transform: skewX(-25deg);
}

button.swal.btn-style-one-other:before {
  z-index: -1;
}

.btn-style-one-other .txt {
  position: relative;
  z-index: 1;
}

.btn-style-one-other:hover::before {
  left: 0%;
  -webkit-transform: scale(1, 1);
  transform: scale(1, 1);
}

.btn-style-one-other:hover {
  color: #ffffff;
}
.h-60 {
  height: 60px !important;
}
.remove_line_bottom {
  border-bottom: 0px solid #ddd;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.ui-widget-content {
  text-align: left;
}

.ui-menu .ui-menu-item:hover {
  background-color: rgba(177, 252, 169, 0.2) !important;
}

.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active,
a.ui-button:active,
.ui-button:active,
.ui-button.ui-state-active:hover {
  /* border: 1px solid #003eff;
	background: #007fff; */
  background-color: rgba(177, 252, 169, 0.2) !important;
  font-weight: normal;
  color: #000;
  font-weight: 300;
}
.ui-widget.ui-widget-content {
  border-top: px solid rgba(29, 29, 29, 0.638);
}
.ui-widget {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  font-weight: 300;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}
.autocomplete-suggestions {
  text-align: left;
  border: 1px solid #999;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  background: #fff;
  overflow: auto;
  font-family: 'Kanit', sans-serif;
}
.autocomplete-suggestion {
  padding: 5px 5px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
.autocomplete-selected {
  background: #f0f0f0;
}
.autocomplete-suggestions strong {
  font-weight: normal;
  color: #74aa42;
}
.autocomplete-group {
  padding: 2px 5px;
}
.autocomplete-group strong {
  display: block;
  /* border-bottom: 1px solid #000; */
}

.ui-autocomplete .highlight {
  color: #74aa42;
  font-weight: 500;
}
.in-keyword {
  font-weight: 300 !important;
  font-size: 17px !important;
}
.news-section-two .inner-box {
  background-color: #fff;
}
.news-section-two .inner-box .lower-content {
  position: relative;
  padding: 30px 30px;
}
.xdsoft_autocomplete_dropdown {
  font-size: 16px;
  font-weight: 300;
}
.xdsoft_autocomplete_dropdown div {
  padding: 20px 10px;
}
.xdsoft_autocomplete .xdsoft_autocomplete_dropdown {
  border-top: 0px solid #ccc !important;
}

.main-footer.style-three {
  /* background: linear-gradient(to right, #0c2859 0%, #05142c 100%); */
  background-image: url(../images/background/bg-footer.png);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.mybanner {
  background-image: url(../images/main-slider/banner.png);
}
.sticky-mobile-nav-toggler {
  display: none;
  position: 'absolute';
  right: 20px;
  top: 20px;
  z-index: 99;
  font-size: 36px;
}
@media (max-width: 991.98px) {
  .display_search_result {
    margin-left: 30px;
    margin-right: 0px;
  }
  .main-footer.style-three {
    background: linear-gradient(to right, #0c2859 0%, #05142c 100%);
  }
  .main-footer .widgets-section {
    padding-bottom: 0px;
  }
}

@media (max-width: 767.98px) {
  .sticky-mobile-nav-toggler {
    display: inline-block;
  }
  .box_search {
    display: inherit;
  }
  .mybanner {
    background-image: url(../images/background/mobile-banner.png);
  }
  .mo_h1 {
    text-align: center;
    font-size: 50px;
  }
  .mo_h1 {
    text-align: center;
    font-size: 50px;
  }
  .mo_title {
    text-align: center;
    font-size: 22px !important;
  }

  .newsletter-form .form-group input[type='text'],
  .newsletter-form .form-group input[type='email'],
  .newsletter-form .form-group input[type='file'] {
    padding: 10px 22px 10px 20px;
  }

  .display_search_result {
    margin-left: 0px;
    margin-right: 0px;
  }
  .video-thumbnail::before {
    opacity: 1;
  }
  .team-block-two {
    margin-bottom: 80px;
  }
  /* .team-block-two .inner-box .lower-content {
    left: 29px;
    bottom: 10px;
  } */
  .team-block-two .inner-box .lower-content h3 {
    line-height: 16px;
  }
  .team-block-two .inner-box .lower-content h3 a {
    font-size: 15px;
  }
  .testimonial-section {
    padding: 0px 0px 60px;
  }
  .testimonial-section .owl-dots {
    margin-top: 5px;
  }
  .main-footer.style-three {
    background: linear-gradient(to right, #0c2859 0%, #05142c 100%);
  }
  .main-footer .widgets-section {
    padding-bottom: 0px;
  }
  .team-block-two .inner-box2 {
    background: linear-gradient(to top, #05142c 0%, #0c2859 100%);
    color: #fff;
  }
  .team-block-two .inner-box .lower-content h3 a {
    color: #fff;
  }
}

@media (max-width: 1550px) {
  .pattern-layer-custom,
  .testimonial-section .circle-layer {
    display: none;
  }
}

.consent_cookie_container {
  display: block !important;
}
.btn-content-detail {
  background-color: #fff;
  color: #05142c;
  padding: 7px 10px;
  font-size: 13px;
  border-radius: 0%;
  position: absolute;
  left: 15px;
  top: 105px;
  border: 1px solid #05142c;
}

.modal-event .modal-content {
  padding: 0;
}
.modal-event .modal-body {
  padding: 0;
}
.modal-event .modal-header {
  height: 0;
  padding: 0;
}
.modal-event .btn-close {
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 99;
}
.suggestionSearch {
  z-index: 9 !important;
  position: absolute;
  top: 48px;
  left: 0;
  background: #fff;
  padding: 0;
  width: 100%;
  border-top: 1px solid #ccc;
  border-radius: 0px 0px 8px 8px;
}

.suggestionSearch li {
  padding: 8px 20px;
  border-bottom: 1px solid #ccc;
}
.suggestionSearch li:hover {
  background: #f5f5f5;
  cursor: pointer;
}
.suggestionSearch-innerPage {
  border: 1px solid #ccc;
}

.max-height-pic-195 .image {
  max-height: 195px;
  overflow: hidden;
}

footer a:hover {
  color: #00baff !important;
}

.mobile-menu .navigation .dropdown ul li {
  background-color: #f3f3f3;
}
.cookie-consent-content a:hover {
  text-decoration: underline;
}
@media (min-width: 768px) {
  .btn-accept-cookie {
    position: absolute;
    top: 40px;
    right: 10%;
  }
}
@media (max-width: 768px) {
  .wrap-btn-accept-cookie {
    width: 100%;
    text-align: center;
  }
}

.content-selection-container {
  position: relative;
}

.content-selection-container > .check-icon {
  display: none;
  position: absolute;
  top: 35%;
  left: 45%;
}

.content-selection-container.active > .check-icon {
  display: block;
}

.content-selection-image {
  transition: all 0.3s;
  cursor: pointer;
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.content-selection-image:hover {
  box-shadow: 0 1rem 3rem rgb(0 0 0 / 18%) !important;
}

.content-selection-image-container {
  position: relative;
  cursor: pointer;
}

.content-selection-image-container::after {
  transition: all 0.3s;
  border-radius: var(--bs-border-radius) !important;
}

.content-selection-image-container.active::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  overflow: hidden;
}

#tick-mark {
  position: absolute;
  display: none;
  width: 30px;
  height: 30px;
  top: 40%;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 1000;
}

.content-selection-image-container.active > #tick-mark {
  display: inline-block;
  background-color: #00baff;
  border-radius: 50%;
  padding: 10px;
  width: 50px;
  height: 50px;
}

#tick-mark::before {
  position: absolute;
  left: 20%;
  top: 48%;
  height: 15px;
  width: 5px;
  background-color: #fff;
  content: '';
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

#tick-mark::after {
  position: absolute;
  left: 11px;
  bottom: 12px;
  height: 5px;
  width: 27px;
  background-color: #fff;
  content: '';
  transform: translateX(10px) rotate(-45deg);
  transform-origin: left bottom;
}

.button-column-reverse-container {
  display: flex;
  flex-direction: row;
  column-gap: 12px;
}

@media (max-width: 768px) {
  .button-column-reverse-container {
    flex-direction: column-reverse;
  }
}
